import React from 'react';
import {
  Layout,
  HomePageIntroduction,
} from '../components';


const IndexPage = () => (
  <Layout>
    <HomePageIntroduction />
  </Layout>
);


export default IndexPage;
